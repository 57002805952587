import styled from 'styled-components';
import Navbar from 'components/navbar/Navbar';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { gameSlideItems } from 'components/homepage/slider/SliderContent';

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import "./styles.css";
import { NAVIGATION_BAR_HEIGHT } from 'utility/css/values';

const Container = styled.div`
  width: 100%;
  height: calc(90vh - ${NAVIGATION_BAR_HEIGHT}px);
  min-height: 600px;
  margin-bottom: 10vh;
`;

const SlideContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => props.backgroundPosition};
  background-image: url(${(props) => props.backgroundImage});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  @media only screen and (max-width: 900px) {
    background-position: ${props => props.backgroundPositionSmall};
  }
`;

const Slide = ({backgroundImage, backgroundPosition, backgroundPositionSmall, Component}) => {
    return (
      <SlideContainer 
        backgroundImage={backgroundImage} 
        backgroundPosition={backgroundPosition}
        backgroundPositionSmall={backgroundPositionSmall}
      >
        <Component/>
      </SlideContainer>
    );
  }

const HomepageSwiper = ({scrolling}) => {
    return (
        <Container>
            <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            pagination={{
                "clickable": true
            }}
            navigation={true}
            className="mySwiper"
            >
            {gameSlideItems.map((item, index) => (
              <SwiperSlide key={index}>
                <Slide
                    backgroundImage={item.backgroundImage}
                    backgroundPosition={item.backgroundPosition}
                    backgroundPositionSmall={item.backgroundPositionSmall}
                    Component={item.component}
                />
              </SwiperSlide>
            ))}
            </Swiper>
            {!scrolling && <Navbar scrolling={false} />}
      </Container>
    );
}

export default HomepageSwiper;