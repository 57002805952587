import Path from "components/Path";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import bulletMainImage from 'img/bullet-main.png';
import bulletFireworksImage from 'img/bullet-youngjamove.gif';
import bulletTreeImage from 'img/bullet-applypattern.gif';
import bulletTackleImage from 'img/bullet-bossmode.gif';
import bulletGraphicsImage from 'img/bullet-adelheid.png';
import level99logo from 'img/bullet-level99logo.png'
import boardgameImage from 'img/bullet-boardgame.webp'
import steamLogo from 'img/Steam_icon_logo.svg';
import googleDriveLogo from 'img/Google_Drive_icon.svg';

import { BUTTON_COLOR_BULLET, BUTTON_HOVER_COLOR_BULLET } from 'utility/css/values';

const Container = styled.div``;

const PresentationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PresentationImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 90%;
  max-height: 550px;
  margin-bottom: 80px;
  @media only screen and (max-width: 853px) {
    width: 90%;
    margin-bottom: 40px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkButtonContainer = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 30px;
  background-color: ${BUTTON_COLOR_BULLET};
  opacity: 0.9;
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${BUTTON_HOVER_COLOR_BULLET};
    }
  }
`;

const LinkImage = styled.img`
  object-fit: contain;
  width: 50px;
`;

const Link = styled.span`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  @media only screen and (max-width: 580px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  width: 70%;
  @media only screen and (max-width: 1350px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 1040px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 853px) {
    width: 90%;
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 530px) {
    font-size: 18px;
  }
`;

const JewelRunContainer = styled.div`
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 853px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const JewelRunImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 50%;
  max-height: 550px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 80px;
  @media only screen and (max-width: 853px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  max-width: 1000px;
  @media only screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const List = styled.ul`
  padding-left: 0px;
  text-align: center;
  list-style-type: none;
`;

const Element = styled.li`
  padding: 5px;
`;

const GraphicsContainer = styled.div`
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 853px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const handleClick = (link) => {
  window.open(link, '_blank');
};

const Bullet = ({ t, i18n }) => {
  return (
    <Container>
      <Path
        segments={[{ name: 'Home', path: '/' }]}
        lastSegment="Bullet: Surge"
      />
      <PresentationContainer>
        <PresentationImage src={bulletMainImage} />
      </PresentationContainer>
      <LinkContainer>
        <LinkButtonContainer
          onClick={(e) =>
            handleClick(
              'https://store.steampowered.com/app/2830200/Bullet/'
            )
          }
        >
          <Link>Bullet: Surge {t('steam_link')}</Link>
          <LinkImage src={steamLogo}/>
        </LinkButtonContainer>
      </LinkContainer>
      <LinkContainer>
       <LinkButtonContainer
        onClick={(e) =>
          handleClick(
            'https://drive.google.com/drive/folders/16U-UnI6mMMLEp9W1dvVEm-9oLxpt-NVp?usp=drive_link'
          )
        }
       >
        <Link>{t('press_kit_link')}</Link>
        <LinkImage src={googleDriveLogo}></LinkImage>
       </LinkButtonContainer>
      </LinkContainer>
      <JewelRunContainer>
        <JewelRunImage src={bulletTreeImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('bullet_about_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_about_1')}</Element>
            <Element>{t('bullet_about_2')}</Element>
            <Element>{t('bullet_about_3')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={bulletFireworksImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('bullet_fun_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_fun_1')}</Element>
            <Element>{t('bullet_fun_2')}</Element>
            <Element>{t('bullet_fun_3')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <GraphicsContainer>
        <JewelRunImage src={bulletTackleImage}></JewelRunImage>
      </GraphicsContainer>
      <DescriptionContainer>
        <Title>{t('bullet_boardgame_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_boardgame_1')}</Element>
            <Element>{t('bullet_boardgame_2')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={boardgameImage}></JewelRunImage>
      </JewelRunContainer>
    </Container>
  );
}

export default withTranslation()(Bullet);
