import Path from "components/Path";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import kaluluPresentationImage from 'img/Kalulu_name.jpg';
import kaluluImage from 'img/Kalulu.png';

const Container = styled.div``

const ImageWrapper = styled.div`
    margin: auto auto 40px auto;
    text-align: center;
    width: 80%;
    max-width: 700px;
`;

const Image = styled.img`
    width: 100%;
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    margin: auto auto 40px auto;
    width: 90%;
    max-width: 1000px;
`;

const Kalulu = ({ t, i18n }) => {
  return (
    <Container>
      <Path
        segments={[{ name: 'Home', path: '/' }]}
        lastSegment="Kalulu"
      />
      <ImageWrapper>
        <Image src={kaluluPresentationImage}></Image>
      </ImageWrapper>
      <Description>
          {t('kalulu_description_alt_1')} <br/> 
          {t('kalulu_description_2')}
      </Description>
      <ImageWrapper>
        <Image src={kaluluImage}></Image>
    </ImageWrapper>
    </Container>
  );
}

export default withTranslation()(Kalulu);