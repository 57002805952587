import Path from "components/Path";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import goodDayFireworksImage from 'img/goodDay-fireworks.gif';
import goodDayTreeImage from 'img/goodDay-tree.gif';
import goodDayTackleImage from 'img/goodDay-tackle.gif';
import goodDayGraphicsImage from 'img/goodDay-running.png';
import steamLogo from 'img/Steam_icon_logo.svg';

const Container = styled.div`
`;

const MultiplayerPresentation = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 853px) {
    width: 90%;
    margin-bottom: 40px;
  }

`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkButtonContainer = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 30px;
  background-color: #88446A;
  opacity: 0.9;
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: #832D75;
    }
  }
`;

const LinkImage = styled.img`
  object-fit: contain;
  width: 50px;
`;

const Link = styled.span`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  @media only screen and (max-width: 580px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  width: 70%;
  @media only screen and (max-width: 1350px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 1040px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 853px) {
    width: 90%;
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 530px) {
    font-size: 18px;
  }
`;

const JewelRunContainer = styled.div`
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 853px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const JewelRunImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 50%;
  max-height: 550px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 80px;
  @media only screen and (max-width: 853px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  max-width: 900px;
  @media only screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const List = styled.ul`
  padding-left: 0px;
  text-align: center;
  list-style-type: none;
`;

const Element = styled.li`
  padding: 5px;
`;

const GraphicsContainer = styled.div`
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 853px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 480px;
  width: 853px;
  @media only screen and (max-width: 853px) {
    width: 90%;
    padding-bottom: 50.64%;
  }
`

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const VideoContainer = styled.div`
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  @media only screen and (max-width: 853px) {
    margin-bottom: 40px;
  }
`;

const handleClick = (link) => {
  window.open(link, '_blank');
};

const GoodDay = ({ t, i18n }) => {
  return (
    <Container>
      <Path
        segments={[{ name: 'Home', path: '/' }]}
        lastSegment="Jewel Run"
      />
      <VideoContainer>
        <Video>
          <Iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/0EukPPdzYJg?si=JBNS0OuU4Fop6KT4"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Video>
      </VideoContainer>
      <LinkContainer>
       <LinkButtonContainer
        onClick={(e) =>
          handleClick(
            'https://store.steampowered.com/app/2097850/A_Good_Day/'
          )
        }
       >
        <Link> Jewel Run {t('steam_link')}</Link>
        <LinkImage src={steamLogo}></LinkImage>
       </LinkButtonContainer>
      </LinkContainer>
      <DescriptionContainer>
        <MultiplayerPresentation>
          <JewelRunImage src={goodDayFireworksImage} />
          <JewelRunImage src={goodDayTreeImage} />
        </MultiplayerPresentation>
        <Title>{t('goodday_about_title')}</Title>
        <Description>
          <List>
            <Element>{t('goodday_about_1')}</Element>
            <Element>{t('goodday_about_2')}</Element>
            <Element>{t('goodday_about_5')}</Element>
            <Element>{t('goodday_about_6')}</Element>
            <Element>{t('goodday_about_3')}</Element>
            <Element>{t('goodday_about_4')}</Element>
            
            
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={goodDayTackleImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('goodday_fun_title')}</Title>
        <Description>
          <List>
            <Element>{t('goodday_fun_1')}</Element>
            <Element>{t('goodday_fun_2')}</Element>
            <Element>{t('goodday_fun_3')}</Element>
            <Element>{t('goodday_fun_4')}</Element>
            <Element>{t('goodday_fun_5')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <GraphicsContainer>
        <JewelRunImage src={goodDayGraphicsImage}></JewelRunImage>
      </GraphicsContainer>
    </Container>
  );
}

export default withTranslation()(GoodDay);
