import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import {
  BUTTON_COLOR,
  BUTTON_HOVER_COLOR,
} from 'utility/css/values';

const Container = styled.div``;

const NotFoundContainer = styled.div`
  padding-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  color: grey;
`;

const Button = styled.button`
  margin-top: 20px;
  background-color: ${BUTTON_COLOR};
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${BUTTON_HOVER_COLOR};
    }
  }
`;

const NotFound = ({ t }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Container>
      <NotFoundContainer>
        <Text>{t('page_not_found')}</Text>
        <Button onClick={() => navigate('/')}>{t('go_to_homepage')}</Button>
      </NotFoundContainer>
    </Container>
  );
};

export default withTranslation()(NotFound);
