import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop';
import About from 'pages/About';
import Homepage from 'pages/Homepage';
import GoodDay from 'pages/GoodDay';
import Bullet from 'pages/Bullet';
import Kalulu from 'pages/Kalulu';
import Component from 'Component';
import PrivacyPolicy from 'pages/PrivacyPolicy'
import { translatePath } from 'utility/i18n/i18n';
import NotFound from 'pages/NotFound';
import { NAVIGATION_BAR_HEIGHT } from 'utility/css/values';

const RoutePath = {
  home: '',
  kalulu: 'games/kalulu',
  goodDay: 'games/jewel-run',
  bullet: 'games/bullet',
  about: 'path.about',
  privacy_policy: 'path.privacy-policy'
};

const App = ({ t, i18n }) => {
  const [scrolling, setScrolling] = useState();
  const lang = i18n.language;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    if (window.scrollY < NAVIGATION_BAR_HEIGHT && scrolling === true) {
      setScrolling(false);
    } else if (
      window.scrollY >= NAVIGATION_BAR_HEIGHT &&
      scrolling !== true
    ) {
      setScrolling(true);
    }
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="*"
          element={
            <Component Component={NotFound} scrolling={true} path={''} />
          }
        />
        <Route path={'/'} element={<Navigate to={`/${lang}`} />} />
        <Route
          path={translatePath(RoutePath.home, lang)}
          element={
            <Component
              Component={Homepage}
              scrolling={true}
              animateNavbar={false}
              path={RoutePath.home}
            />
          }
        />
        <Route
          path={translatePath(RoutePath.kalulu, lang)}
          element={
            <Component
              Component={Kalulu}
              scrolling={true}
              path={RoutePath.kalulu}
            />
          }
        />
        <Route
          path={translatePath(RoutePath.privacy_policy, lang)}
          element={
            <Component
              Component={PrivacyPolicy}
              scrolling={true}
              path={RoutePath.privacy_policy}
            />
          }
        />
        <Route
          path={translatePath(RoutePath.goodDay, lang)}
          element={
            <Component
              Component={GoodDay}
              scrolling={true}
              path={RoutePath.goodDay}
            />
          }
        />
        <Route
          path={translatePath(RoutePath.bullet, lang)}
          element={
            <Component
              Component={Bullet}
              scrolling={true}
              path={RoutePath.bullet}
            />
          }
        />
        <Route
          path={translatePath(RoutePath.about, lang)}
          element={
            <Component
              Component={About}
              scrolling={true}
              path={RoutePath.about}
            />
          }
        />
        {/* TODO: change image and remove this line <Route path='error' element={<ErrorBoundary />} />*/}
      </Routes>
    </>
  );
};

export default withTranslation()(App);
