import { useNavigate } from 'react-router';
import { translatePath } from 'utility/i18n/i18n';
import styled from 'styled-components';
import {
  DARK_SCHEME_COLOR,
  DARK_SCHEME_COLOR_BACKGROUND,
  NAVIGATION_BAR_HEIGHT,
} from 'utility/css/values';
import Footer from 'components/Footer';
import Navbar from 'components/navbar/Navbar';
import { withTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ErrorBoundary } from 'components/error/ErrorBoundary';

const Container = styled.div`
  margin-top: ${(props) => props.$scrolling ? NAVIGATION_BAR_HEIGHT + 'px' : '0px'};
  @media (prefers-color-scheme: dark) {
    background-color: ${DARK_SCHEME_COLOR_BACKGROUND};
    color: ${DARK_SCHEME_COLOR};
  }
`;

const Component = ({ t, i18n, Component, scrolling, animateNavbar, path }) => {
  const navigate = useNavigate();

  // TODO: maybe useBlocker is the way when it will exist !
  //useBlocker();

  useEffect(() => {
    i18n.on('languageChanged', function (lng) {
      navigate(translatePath(path, lng));
    });
  });

  return (
    <ErrorBoundary key={path}>
      <Container $scrolling={scrolling}>
        <Component scrolling={scrolling} />
        <Footer />
        {scrolling && <Navbar animate={animateNavbar} scrolling={scrolling} />}
      </Container>
    </ErrorBoundary>
  );
};

export default withTranslation()(Component);
