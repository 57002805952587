import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { NAVIGATION_BAR_HEIGHT } from 'utility/css/values';

const TitleImage = styled.svg`
  height: ${NAVIGATION_BAR_HEIGHT}px;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`

const NavbarTitle = ({setNavigateIsOpen}) => {

    const navigate = useNavigate();

    /* svg created with npx @svgr/cli -- src/img/icon.svg */
    return (
      <TitleImage
      onClick={() => {
        navigate('/');
        setNavigateIsOpen(false);
      }}
      viewBox="0 0 340 187"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.5,
      }}
    >
      <path
        style={{
          fill: "none",
        }}
        d="M117.119 327.822h339.742v186.45H117.119z"
        transform="translate(-117.119 -327.822)"
      />
      <path
        d="M290.08 488.336c-.795-3.068-4.187-3.817-5.733-3.31-13.768 4.515-4.103 29.24 7.368 12.439"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "6.06px",
        }}
        transform="matrix(1.18521 0 0 1.32588 -180.102 -508.089)"    />
      <path
        d="m295.493 496.075-7.556.404"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "3.85px",
        }}
        transform="matrix(.77795 .04587 -.1209 2.67589 -2.709 -1191.522)"
      />
      <path
        d="M303.349 502.452c.291-.524 5.398-16.436 7.588-19.823 1.009-1.56 4.421-1.973 5.553-.498 2.535 3.303 9.66 20.321 9.66 20.321l-4.798-10.092-14.103-.007"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "8.52px",
        }}
        transform="matrix(.77646 0 0 .99973 -61.382 -343.747)"   
      />
      <path
        d="m337.784 501.599-.602-18.472 7.325 9.164 8.96-9.164.838 19.325"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "7.84px",
        }}
        transform="matrix(.77646 0 0 1.13409 -62.286 -411.447)"  
      />
      <path
        d="m379.884 503.298-13.681.205-.21-20.376h14.41-14.41l.098 9.552 9.027-.288"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "7.84px",
        }}
        transform="matrix(.77646 0 0 1.13409 -62.021 -411.342)"  
      />
      <path
        d="M401.214 486.99c-1.476-4.613-9.19-5.199-12.969-.832-1.9 2.196-2.251 5.687-.356 7.122 2.271 1.72 10.94-.422 13.986 3.199 1.362 1.619 1.304 3.418.645 4.943-2.527 5.843-13.291 1.843-13.093 2.012"
        style={{
          fill: "none",
          stroke: "#a07e65",
          strokeWidth: "8.15px",
        }}
        transform="matrix(.77646 0 0 1.07026 -59.325 -380.646)"  
      />
      <path
        d="M170.249 364.475c-1.675-2.269-5.455-5.921-5.931-6.033-.476-.111 2.852 4.743 3.076 5.366.224.624-1.92-2.393-1.732-1.624.407 1.655 4.257 11.392 4.171 11.557-.086.164-3.788-8.923-4.687-10.571-.158-.288-.69.353-.712.68-.032.494.636 2.248.515 2.283-.12.035-1.08-2.121-1.238-2.073-.159.048.382 2.24.287 2.359-.094.119-.345-1.995-.852-1.643-.89.619-4.49 5.355-4.49 5.355l1.412 84.391c2.38-.778 5.618-2.822 5.605-3.595-.173-10.039.355-18.978.547-22.876.034-.691 3.202.089 4.724.101 1.47.012 4.409-.027 4.409-.027l.457.353 1.18-.105s1.696.17 2.072.261c.11.026.215.189.187.283-.028.094-.355.281-.355.281l-1.24 24.091c-.063 2.779-.92 4.458-2.246 5.486-3.589 3.057-6.365 6.522-10.551 6.91-4.415.409-12.463.049-15.936-4.454-.753-1.183-.984-3.255-1.029-5.647l-1.367-44.018.3-.682-.3-15.795.086-26.55c.53-3.438 1.592-5.334 4.206-7.938a57.575 57.575 0 0 0 5.776-5.996c2.113-1.804 3.721-2.576 5.008-2.693 2.54-.445 4.764.077 6.687 1.522 2.727 2.496 4.325 5.958 5.906 9.021 1.532 2.968 3.341 8.12 3.581 9.358.183.943-1.787-2.26-2.141-1.929-.354.33.914 4.813.016 3.912-.897-.902-3.512-6.762-5.401-9.321ZM191.613 354.393c1.256.113 9.357 1.427 10.763 1.748 1.203.275-3.716-.059-3.716-.059 1.99.029 5.427.825 5.923.978.497.152-3.086-1.344-2.207-.919.882.427 5.084 1.021 7.471 3.138l7.229 4.683s-10.553-6.654-9.854-6.292c.7.361 9.604 4.633 14.05 8.463l-4.108 3.69-2.178 3.796-1.035-.216-1.895 2.334-1.119-.234-.163-1.033s-3.193-3.454-5.141-4.54c-1.947-1.086-6.544-1.979-6.544-1.979l-.429-.932c-3.129-.837-7.733-1.173-9.218-1.555-.258-.066.043-.738.309-.735.845.008 5.096.959 4.758.786-.339-.173-5.787-1.34-6.788-1.824-.4-.193 1.025-.799.78-1.079-.244-.28-2.365-.433-2.247-.6.118-.167 2.257-.134 2.953-.402.534-.205 
  4.693-.448 4.734-.718.041-.27-4.286-.611-4.488-.901-.202-.29.193-1.198-.237-1.325-.743-.22-1.444-.087-1.378-.183.066-.095.84-.16 1.773-.389.41-.101.581-.675.986-.795.415-.124 1.871.122 1.506.053s-5.988.046-6.334-.068c-.346-.113 4.196-.482 4.259-.614.062-.132-2.054-.602-1.956-.731.099-.129 2.37.131 2.547-.044.177-.174-1.154-.782-.776-.851.699-.128 3.267-.032 4.967.083 0 0-4.453-.847-3.197-.734Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path
        d="M191.613 354.393c1.256.113 9.357 1.427 10.763 1.748 1.203.275-3.716-.059-3.716-.059 1.99.029 5.427.825 5.923.978.497.152-3.086-1.344-2.207-.919.882.427 5.084 1.021 7.471 3.138l7.229 4.683s-10.553-6.654-9.854-6.292c.7.361 9.604 4.633 14.05 8.463l-4.108 3.69-2.178 3.796-1.035-.216-1.895 2.334-1.119-.234-.163-1.033s-3.193-3.454-5.141-4.54c-1.947-1.086-6.544-1.979-6.544-1.979l-.429-.932c-3.129-.837-7.733-1.173-9.218-1.555-.258-.066.043-.738.309-.735.845.008 5.096.959 4.758.786-.339-.173-5.787-1.34-6.788-1.824-.4-.193 1.025-.799.78-1.079-.244-.28-2.365-.433-2.247-.6.118-.167 2.257-.134 2.953-.402.534-.205 4.693-.448 4.734-.718.041-.27-4.286-.611-4.488-.901-.202-.29.193-1.198-.237-1.325-.743-.22-1.444-.087-1.378-.183.066-.095.84-.16 1.773-.389.41-.101.581-.675.986-.795.415-.124 1.871.122 1.506.053s-5.988.046-6.334-.068c-.346-.113 4.196-.482 4.259-.614.062-.132-2.054-.602-1.956-.731.099-.129 2.37.131 2.547-.044.177-.174-1.154-.782-.776-.851.699-.128 3.267-.032 4.967.083 0 0-4.453-.847-3.197-.734Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".44px",
        }}
        transform="matrix(-.14811 -.89516 -.98659 .16324 582.162 
  208.236)"
      />
      <path
        d="M191.613 354.393c3.379-.701 13.981 1.466 10.763 1.748-1.228.108-3.716-.059-3.716-.059 1.99.029 5.427.825 5.923.978.497.152-3.086-1.344-2.207-.919.882.427 5.084 1.021 7.471 3.138l6.272 5.803s-9.596-7.774-8.897-7.412c.7.361 7.465 5.271 11.911 9.101l-1.969 3.052-2.178 3.796-1.035-.216-.975 1.893-1.129-.374-.557-.551s-2.677-4.821-4.625-5.907c-1.947-1.087-4.898-2.154-4.898-2.154l-1.434-.484c-3.835-1.007-5.916-2.332-10.522-3.756-.254-.079 6.627.68 6.893.683.844.008.338.173 0 0-.339-.173-4.479-1.059-5.48-1.543-.4-.193-3.828-.643-4.073-.922-.244-.28 6.654.291 
  6.771.124.118-.167-2.534.9-1.838.632.534-.206 1.816.118 1.857-.152.041-.27-4.286-.611-4.488-.901-.202-.29.193-1.198-.237-1.325-.743-.22-1.444-.087-1.378-.183.066-.095.84-.16 1.773-.389.41-.101.581-.675.986-.795.415-.124 1.871.122 1.506.053s-3.334-.227-3.68-.34c-.345-.114 6.86.296 6.922.164.063-.132-7.371-1.108-7.273-1.237.099-.129 6.812.356 6.989.182.177-.174-5.596-1.008-5.218-1.077.699-.128 3.267-.032 4.967.083 0 0-4.432-.478-3.197-.734Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="rotate(44.255 532.957 220.744)"
      />
      <path
        d="m204.675 430.387 1.508-.276 3.276 2.762.893.44 2.625 2.176-.136.416.646.278.786.561.002 2.154-11.139 11.546-9.897 5.127-7.169-4.994s-.078-31.584-.119-31.768c-.041-.183.432-.352.432-.352-.022-16.325 2.052-41.985 2.374-47.993.179-3.342.695-5.183.959-4.011.226 1.003.801-4.007.794-2.98-.017 2.451-2.502 35.092-2.296 33.639.207-1.453 2.412-31.046 2.679-34.354.177-2.184-.52 3.064-.433 3.66.088.596.746-2.967 1.057-2.976.311-.009 1.185 1.92 1.439 1.318.254-.603-.147-1.472-.047-1.426.1.046.57.454.648 1.704.019.297.525.387.58.68.09.474-.138 1.711-.043 2.161.056.266.508.287.613.538.113.269-.076 1.14.063 1.074.14-.067-.641 11.467-.496 10.977.139-.47-.076-1.284.096-1.466.172-.183.831.692.937.373.269-.808.416-1.666.579-2.694.052-.326.25-.787.3-.944.069-.694.475-5.215.416-4.162s-.623 6.003-.672 7.955c-.011.44.437.793.478 1.232.047.5-.213 1.324-.198 1.765.01.307.271.57.286.878.031.64.011 2.652-.101 2.963-.11.304 1.238-5.661 1.338-4.96.1.7-.961 8.976-.737 9.164.223.188 1.249-3.097 1.44-4.319.191-1.222-.109 1.888-.109 1.888s.111-3.367.109-1.888c-.004 3.685-1.255 12.556-.921 21.695l-.742-2.784.742 2.784h3.266l-2.121.491 2.888-.118-.767-.373s3.496-.642 4.852-.699c1.101-.047 3.287.354 3.287.354l.594.658.217 3.702-.484.287.333.671.294 3.589-.563.423.368.653-.284 2.212-.714.738-1.019-.515-5.274-.076-4.853-.036-.79 20.947 7.93-10.469ZM219.107 394.006l-1.996.886-.899-.177 1.106 2.973.619.215.091.861 1.315 1.988s-1.286 36.655-1.149 42.157c.044 1.743.3 5.372.521 5.205.221-.166.669-19.177.803-19.078.134.099-.05 19.026 0 19.673.05.647.135-2.876.302-2.918.168-.042 1.153 
  10.18 1.236 9.266.146-1.615.176-12.782.141-18.047-.016-2.481.587-14.239.863-12.543.276 1.696-.06 17.234.317 19.178.063.327.843 9.729.963 2.776.006-.347.073-12.431.181-12.818.079-.287-.108 3.999-.068 3.704.097-.708.092 2.398.258 2.803.167.404 1.176 4.237 1.274 6.414.212 4.723.302 8.684.466 9.073.163.389.036-5.391.513-6.736.478-1.346 1.514 14.228 1.884 11.511.37-2.716.144-29.066.338-27.81.194 1.256 2.391 29.606 2.954 26.859 1.233-6.028-.224-57.989-.224-57.989s9.969-6.759 15.51-13.006c4.378-4.935 7.442-10.672 6.663-19.732-.387-4.5-4.379-7.567-8.454-9.513-5.075-2.424-16.988-3.98-21.254-4.35-.851-.073-4.234 2.123-4.411 4.084-.6 6.642.137 35.091.137 35.091Zm11.14-26.437.399 20.127c11.118-9.476 12.727-14.768 10.518-17.185-2.075-2.271-10.917-2.942-10.917-2.942Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path
        d="M233.15 404.886c.136 1.696 5.336 27.19 7.138 35.767 1.104 5.257 2.982 10.364 3.67 15.691.797 6.173 1.113 21.344 1.113 21.344l-.677 1.448 2.615.299 1.154-.255.224.546 4.459-.828 4.066-.023.703-1.344s-1.193-14.715-2.392-21.99c-1.456-8.839-5.744-28.919-6.344-31.04-.6-2.12-.493-2.082-.631-1.896-.138.186.364 6.38-.199 3.011-.165-.992.394-2.024.199-3.011-.682-3.455-2.824-12.267-3.199-13.395-.376-1.129 1.174 6.849.944 6.623-.229-.226-2.08-8.349-2.419-9.473-.339-1.124.554 3.061.384 2.729-.17-.332-1.576-5.887-1.403-4.721.174 1.166 2.392 10.81 2.444 11.716.052.906-2.492-7.483-3.122-9.109-.111-.286-.57-.349-.659-.643-.497-1.627.67 4.835.51 4.71-.161-.126-1.558-6.494-1.475-5.461.063.775.786 4.121.702 4.265-.085.144-1.014-2.4-1.208-3.399-2.116-10.893-.324.088.839 7.059.099.597-1.883-9.216-2.282-10.394-.354-1.05.098 3.025-.109 3.32-.207.296-.955-.933-1.132-1.546-.639-2.223-1.311-4.393-1.436-4.393s.563 2.732.686 4.393c.054.727-.976-1.131-1.217-1.82-.447-1.279-.267.652-.196 1.542.071.89.397 1.853.62 
  3.798.027.241-.578.233-.552.475.084.818.954 3.472 1.061 4.433.052.463-.51.876-.418 1.332.574 2.86 4.273 17.453 3.863 15.826-.41-1.627-6.459-27.283-6.324-25.586ZM273.041 395.465l-1.26-30.112.139-1.677.64-.4-.367-.504-2.954-.037-.516.286-.373-.421-3.318.175-.961.293-1.183-.199-2.983.231-.756 1.845 2.288 32.504 1.106 16.521-.011 10.957.551 13.766.432-4.139.464 6.748.274-4.802.805 6.949.161-4.091.749 3.708-1.189-11.645 2.398 14.096.044-4.449 1.053 1.131.092-2.426.031-3.368.835 3.614 1.165 7.198.186-6.051 2.879 4.903-.188-12.533-.741-10.943 1.565 18.642.183-6.165.764 6.191-1.526-33.459s15.44-2.315 22.085-3.34c5.841-.902 12.953-1.302 17.263-4.115 4.309-2.814 6.789-7.72 7.868-11.455 1.103-3.822.189-8.435-3.051-11.064-3.184-2.584-8.179-4.274-8.179-4.274-10.732-2.935-27.337-7.054-31.803-8.272-.794-.216 2.65.921 2.273.958-.378.036-4.338-.784-4.538-.739-.2.045 3.553.858 3.336 1.009-.217.151-4.276-.226-4.637-.105-.361.121 2.43.7 2.469.83.04.131-2.572-.116-2.231-.046.341.07 2.885.12 4.277.467 2.68.667 10.645 3.998 15.799 4.748-4.747-.554-12.442-3.873-14.349-4.318-.38-.089 3.299 1.611 2.91 1.647-.391.035-4.911-1.488-5.257-1.434-.325.05 3.504 1.76 3.175 1.756-.668-.008-6.562-1.755-7.184-1.806-.205-.017 3.658 1.491 3.452 1.503-.592.033-3.464-.236-3.005-.095.459.142 4.799.664 5.76.944.234.068.24.665.005.732-.325.093-2.156-.203-1.958-.173.199.03 2.603.201 3.148.353.184.051-.062.511.123.559.441.115 2.341.006 2.524.127.183.122-.993.505-1.425.603-.378.086-.778.02-1.164-.016-1.184-.111-5.715-.69-5.94-.649-.225.041 4.132.683 4.589.894.456.211-1.222.345-1.851.374-.912.042-3.539-.224-3.618-.12-.08.105 2.708.544 3.143.746.214.1-.308.399-.534.468-.486.147-3.143.152-2.382.417 2.738.953 11.65 3.92 18.807 5.302-7.126-1.096-14.075-3.788-17.396-4.481-.833-.174-3.357.121-2.531.324 1.281.316 9.863 1.379 10.218 1.571.356.192-6.446-.393-8.084-.419-.589-.009-2.331.328-1.746.266 5.504-.582 36.167 6.402 36.429 8.311 0 0 .233 2.928-2.645 4.77-5.36 3.43-34.623 4.905-34.623 4.905Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path
        d="m312.045 438.807-53.08-4.342-1.831-.288-.74-.74-.214.298-.432 2.344.171 1.028-.321.397-.192 4.456-.498 3.936 1.028.989s30.438 1.018 40.87 1.615c7.115.407 17.61 1.572 21.724 1.963 1.462.139 3.897-1.063 4.129-2.514.642-4.02.88-15.351.403-21.1-.372-4.486-2.297-8.935-3.821-12.843l1.111 4.111c-.672-1.309.848.961 0 0l-.406-.851c-.862-1.636-1.839-3.112-3.012-4.318l2.159 3.988-5.068-6.099 4.42 6.342-.464-1.255a49.465 49.465 0 0 0-7.336-8.537c2.221 2.768 4.29 5.505 5.489 8.066l-.369.476-.987-1.143 1.356 2.439-.511.128 1.245 3.543-3.016-5.524-1.397-2.334.05 2.17-.05-2.17c-2.228-3.718-.035-.723-2.787-3.83 1.823 2.409-.401.832.674 2.97l.707 1.273 1.242 2.34-2.21-1.997-.074.754.955 1.782-5.119-6.366 4.968 7.476.699 1.304 1.796 7.166s-1.388-4.483-2.528-6.514c-1.309-2.332-1.765-2.933-1.765-2.933s.18.483.907 3.447c1.098 4.481 2.379 12.3 2.125 18.897ZM326.56 376.92c-.431 9.741-2.61 59.932-2.759 74.04-.017 1.575.426 2.92 1.832 3.632 1.635.827 5.099 1.618 7.492.849 4.126-1.327 16.377-12.736 16.202-13.508l1.512-1.88s.996-.272.86-.667c-.135-.395-1.674-1.705-1.674-1.705l-.624-.188-.008-.488-2.883-2.525-.69-.14-.243-.627-2.37-2.095-1.628.195-6.63 7.763c-.207-6.336-.295-12.865.029-20.065 0 0 9.138.065 11.261.122.498.013 1.085.081 1.478.217.335.115.653.597.879.597.227.001.432-.345.478-.595.1-.546.124-2.68.124-2.68l-.361-.349.689-.739-.258-3.438-.318-.591.258-.664-.108-3.79s-2.197-.733-3.335-.734c-2.219-.002-10.02-.192-10.02-.192.146-7.927.986-19.588 1.09-22.31.076-1.997-.467 5.98-.467 5.98s.49-5.256.467-5.98c-.019-.58-.468 1.071-.604 1.636-.222.922-.652 4.168-.726 
  3.895-.074-.272.268-4.132.281-5.531.009-.957-.192-2.694-.204-2.863-.012-.169.111 2.467.132 1.85.026-.8.24-7.85.027-6.649-.213 
  1.2-1.035 12.194-1.307 13.853-.212 1.287-.309-2.965-.328-3.897-.012-.569.209-1.695.209-1.695s-.194 2.264-.209 1.695c-.048-1.786.105-11.752-.075-12.414-.181-.663-.736 7.108-1.006 8.44-.05.249-.612-.197-.615-.451-.01-1.229.58-7.598.552-6.925-.028.673-.5 
  9.534-.72 10.963-.126.812-.496-3.205-.601-2.39-.247 1.922-.805 
  13.849-.881 13.923-.076.075.406-11.163.425-13.475.001-.17-.316-.231-.314-.4.022-1.503.499-8.432.443-8.618-.056-.186-.599 6.539-.777 7.501-.107.575-.278-1.146-.295-1.731-.027-.898.168-2.961.137-3.662-.01-.21-.333-.331-.326-.541.042-1.43.692-8.795.582-8.037-.11.757-.986 11.047-1.242 12.581-.186 1.114-.184-2.993-.296-3.377-.106-.364-.356 1.45-.375 1.072-.045-.859.2-6.131.11-6.227-.091-.097-.431 3.766-.654 5.648-.247 2.075-.791 8.051-.827 6.804-.035-1.247.773-15.48.612-14.284-.161 1.195-1.344 20.479-1.578 21.456-.234.977.405-20.789.175-15.595ZM330.45 367.004c4.89 1.471 21.262 6.226 21.262 6.226l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 
  1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102 1.553.601 1.613.715.059.114-1.898-.377-1.258-.032.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476 8.242 1.091 8.415 1.212.173.121-5.465-.246-7.377-.486-1.392-.174-3.142-.648-4.099-.95-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437.999-.27.868-.195-.13.075-2.753-.005-1.652.645.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269 1.841-.27 1.722-.165-.119.105 2.571 2.145 3.366 2.384Z"      style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path
        d="M330.45 367.004c4.89 1.471 21.262 6.226 21.262 6.226l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102 1.553.601 1.613.715.059.114-1.898-.377-1.258-.032.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476 8.242 1.091 8.415 1.212.173.121-5.465-.246-7.377-.486-1.392-.174-3.142-.648-4.099-.95-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437.999-.27.868-.195-.13.075-2.753-.005-1.652.645.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269 1.841-.27 1.722-.165-.119.105 2.571 2.145 3.366 2.384Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".34px",
        }}
        transform="matrix(.28453 1.23748 -1.11558 .2565 415.974 -397.725)"
      />
      <path
        d="M330.45 367.004c4.89 1.471 21.262 6.226 21.262 6.226l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102 1.553.601 1.613.715.059.114-1.898-.377-1.258-.032.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476-5.16-2.323-4.987-2.202.173.121 3.323.796 1.411.557-1.392-.174 1.472 1.723.515 1.421-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437.999-.27.868-.195-.13.075-2.753-.005-1.652.645.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269 1.841-.27 1.722-.165-.119.105 2.571 2.145 3.366 2.384Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".41px",
        }}
        transform="matrix(-.17763 -1.06049 .98738 -.11967 -222.275 460.43)"
      />
      <path
        d="M330.45 367.004c4.89 1.471 21.262 6.226 21.262 6.226l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102 1.553.601 1.613.715.059.114-1.898-.377-1.258-.032.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476-5.16-2.323-4.987-2.202.173.121 3.323.796 1.411.557-1.392-.174 1.472 1.723.515 1.421-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437.999-.27.868-.195-.13.075-2.753-.005-1.652.645.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269 1.841-.27 1.722-.165-.119.105 2.571 2.145 3.366 2.384Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".41px",
        }}
        transform="matrix(-.17763 -1.06049 .98738 -.11967 -83.883 464.05)"
      />
      <path
        d="M332.577 360.348c.572.263-6.511-4.635-6.217-3.815.562 
  1.573 8.539 9.695 8.539 9.695s16.572 7.17 16.813 7.002l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-2.239-8.158-2.343-8.084-.104.074 1.188 8.135 1.717 8.528.221.164 1.734-6.137 1.461-6.17-.519-.061-2.529-.906-2.449-.855.081.052 2.15.971 2.931 1.165.568.142-.429 6.529-.371 6.657.058.128 1.255-5.888.721-5.888-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.442.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.078.067 1.221 1.508.45 6.649 7.855 6.855 8.134.206.28-5.382-7.879-7.116-8.124a4.547 4.547 0 0 0-1.163.003c-.215.032-.381.291-.597.264-.797-.101 1.554.602 1.613.715.059.114-1.898-.377-1.258-.031.256.138-.614.365-.702.517-.072.123.037.349.173.392.306.096 1.158-.055 
  1.662.184.584.278 1.162 1.089 1.846 1.484.904.521 2.3 1.341 3.576 1.645 1.999.476-5.16-2.323-4.987-2.202.173.121 3.324.796 1.411.557-1.392-.174 1.472 1.722.515 1.421-.59-.186-1.252-.577-1.645-.86-.298-.214-.38-.684-.712-.839-.941-.437.998-.27.867-.195-.13.075-2.753-.005-1.652.645.853.504 2.81 1.675 2.46 1.667-.349-.008-4.452-7.642-5.71-7.991-.968-.269 2.992 6.006 2.873 6.111-.119.106 2.612 2.037 3.367 2.385Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".41px",
        }}
        transform="matrix(-.8317 -.68152 .66462 -.73995 166.645 587.152)"
      />
      <path
        d="M336.226 368.936c4.89 1.471 15.486 4.294 15.486 4.294l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346s-11.52-3.368-14.753-4.254c-1.151-.399-3.785.182-4.278.144-.493-.037 3.551 1.85 3.951 2.172.401.321-1.057-.062-1.547-.242-.688-.252-2.51-1.323-2.578-1.271-.068.053 2.442 1.611 2.17 1.584-.272-.028-3.654-1.773-3.803-1.748-.149.024 2.586 1.53 2.909 1.894.224.252-.638.359-.968.29-.907-.189-4.365-1.501-4.469-1.427-.105.074 3.313 1.478 3.843 1.871.221.164-.393.519-.666.487-.519-.061-2.529-.906-2.448-.855.08.052 2.149.971 2.93 1.165.568.141 1.697-.128 1.756 0 .058.128-.872.769-1.406.769-.855 0-3.551-.881-3.726-.769-.175.112 2.224 1.099 2.676 1.441.163.123-1.256-1.079-1.459-1.054-.232.027-.411 1.078.067 1.221 1.508.45 8.776 1.198 8.982 1.477.206.28-7.509-1.223-9.243-1.467a4.588 4.588 0 0 0-1.162.002c-.215.033-.381.292-.597.265-.798-.102 1.553.601 1.612.715.059.114-1.897-.377-1.257-.032.256.139-.614.366-.703.517-.072.124.037.35.174.392.305.097 1.158-.054 1.661.185.585.278 1.163 1.089 1.846 1.483.904.522 2.3 1.341 3.577 1.645 1.998.477-5.16-2.322-4.988-2.201.173.121 3.324.796 1.411.556-1.391-.174 1.472 1.723.515 1.422-.59-.186-1.252-.577-1.645-.86-.297-.214-.379-.684-.712-.839-.941-.437.999-.27.868-.195-.131.075-2.754-.005-1.653.645.854.503 2.811 1.674 2.461 1.666-.35-.007-3.301-1.364-4.559-1.714-.968-.268 1.84-.27 1.721-.165-.118.105 2.571 2.145 3.367 2.384Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".41px",
        }}
        transform="matrix(.82315 .69182 -.67378 .73162 14.403 -467.101)"
      />
      <path
        d="M324.651 365.416c4.889 1.471 27.061 7.814 27.061 7.814l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 
  1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102-4.246-.987-4.187-.873.059.114 3.902 1.211 4.542 1.556.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476 8.242 1.091 8.415 1.212.173.121-5.465-.246-7.377-.486-1.392-.174-3.142-.648-4.099-.95-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437-4.801-1.858-4.932-1.783-.13.075 3.047 1.583 4.148 2.233.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269-2.87-.487-2.989-.381-.118.105 1.482.773 2.278 1.012Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".43px",
        }}
        transform="matrix(-.90716 .00257 -.00286 -1.00898 600.124 407.682)"
      />
      <path
        d="M331.057 367.528c4.889 1.471 20.655 5.702 20.655 5.702l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346s-9.757-2.316-12.99-3.201c-1.151-.399-4.917-.692-5.41-.729-.493-.037 3.55 1.849 3.951 2.171.401.322-1.057-.062-1.548-.242-.687-.252-2.51-1.322-2.577-1.27-.068.052 2.442 1.611 2.17 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.024 2.586 1.53 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.364-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.062-2.529-.907-2.449-.855.081.051 2.15.97 2.931 1.165.568.141 1.697-.129 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.411 1.078.067 1.221 1.508.45 8.775 1.198 8.981 1.478.206.279-7.508-1.223-9.242-1.467a4.55 4.55 0 0 0-1.163.002c-.215.032-.381.292-.597.264-.797-.101-4.246-.986-4.187-.872.059.114 3.903 1.21 4.543 1.556.255.138-.615.365-.703.517-.072.123.037.349.173.392.306.096 1.159-.055 1.662.184.584.278 1.162 1.089 1.846 1.484.904.521 2.3 1.341 3.576 1.645 1.999.476 8.242 1.09 8.415 1.211.173.121-5.464-.246-7.377-.485-1.392-.174-3.142-.648-4.099-.95-.59-.186-1.252-.576-1.645-.86-.298-.214-.38-.684-.712-.838-.941-.437-4.802-1.858-4.932-1.783-.131.075 3.046 1.583 4.147 2.232.853.504 2.81 1.675 2.46 1.667-.349-.008-3.301-1.365-4.559-1.714-.967-.269-2.87-.487-2.989-.382-.118.105 1.482.774 2.278 1.013Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".41px",
        }}
        transform="matrix(-.9485 -.39478 -.38772 .93152 547.57 -169.43)"
      />
      <path
        d="M324.87 365.095c4.89 1.471 26.842 8.135 26.842 8.135l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102-4.246-.987-4.187-.873.059.114 3.902 1.211 4.542 1.556.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476 8.242 1.091 8.415 1.212.173.121-5.465-.246-7.377-.486-1.392-.174-3.142-.648-4.099-.95-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437-4.801-1.858-4.932-1.783-.13.075 3.047 1.583 4.148 2.233.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269-2.547-.424-2.547-.424s1.26.494 2.055.734Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".43px",
        }}
        transform="matrix(.33493 .84307 -.9377 .37253 472.909 -312.472)"
      />
      <path
        d="M324.651 365.416c4.889 1.471 27.061 7.814 27.061 7.814l1.246.882.506.763 1.414-2.763.098-1.02.641-.181.852-3.394-.165-.622.511-.852.954-3.06-1.42-1.346-19.396-5.313c-1.151-.399-4.917-.691-5.41-.729-.493-.037 3.55 1.85 3.951 2.171.4.322-1.058-.062-1.548-.241-.687-.252-2.51-1.323-2.578-1.271-.067.052 2.443 
  1.611 2.171 1.583-.272-.027-3.654-1.772-3.803-1.748-.149.025 2.585 1.531 2.909 1.895.224.252-.639.359-.969.29-.906-.19-4.365-1.502-4.469-1.428-.104.074 3.314 1.479 3.843 1.872.221.164-.392.519-.665.487-.519-.061-2.529-.907-2.449-.855.081.052 2.15.971 2.931 1.165.568.141 1.697-.128 1.755 0 .058.128-.871.769-1.405.769-.856 0-3.552-.881-3.727-.769-.175.112 2.224 1.099 2.677 1.441.163.123-1.256-1.079-1.459-1.055-.232.028-.412 1.079.067 1.222 1.508.449 8.775 1.198 8.981 1.477.206.28-7.508-1.223-9.242-1.467a4.596 4.596 0 0 0-1.163.002c-.215.033-.381.292-.597.265-.797-.102-4.246-.987-4.187-.873.059.114 3.902 1.211 4.542 1.556.256.138-.614.366-.702.517-.072.124.037.349.173.392.306.096 1.158-.055 1.662.185.584.278 1.162 1.089 1.846 1.483.903.522 2.3 1.341 3.576 1.645 1.999.476 8.242 1.091 8.415 1.212.173.121-5.465-.246-7.377-.486-1.392-.174-3.142-.648-4.099-.95-.59-.185-1.252-.576-1.645-.859-.298-.215-.38-.685-.713-.839-.94-.437-4.801-1.858-4.932-1.783-.13.075 3.047 1.583 4.148 2.233.853.503 2.81 1.674 2.46 1.666-.35-.008-3.301-1.365-4.559-1.714-.968-.269-2.87-.487-2.989-.381-.118.105 1.482.773 2.278 1.012Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".43px",
        }}
        transform="matrix(-.18351 .8884 1.02226 .21116 -39.452 -241.871)"
      />
      <path
        d="M362.946 355.64c2.137.023 5.073.94 6.306.891.986-.039 
  2.278-.861 3.259-.767 2.527.24 8.932.18 12.062 2.718 5.317 4.311 6.526 9.955 5.76 14.841-1.268 8.081-12.016 20.977-12.016 20.977s7.028 34.931 10.991 48.119c4.101 13.643 3.768 23.744 4.321 27.68.441 3.145-.774-3.239-1.007-4.066-.233-.826-.345 4.55-.568 
  4.533-.223-.017-.642-4.891-.768-4.637-.126.255.131 5.084.013 6.165-.029.262-.7.058-.722.321-.053.628.519 2.463.403 3.451-.106.897-.799 2.44-1.101 2.476-.301.036-.536-3.031-.707-2.26-.325 1.457-.767-2.026-.884-1.952-.118.074.266 3.14.176 2.396-.09-.745-.541 1.148-.719.497-.179-.651-.259-2.487-.352-4.404-.026-.534-.407-1.523-.557-1.504-.15.018-.265 1.111-.346 1.615-.074.466-.122.938-.137 1.41-.048 1.516.301 2.277.202 2.093-.099-.184-.638-1.781-.798-3.196-.022-.198-.499.105-.514.303-.128 1.669-.136 3.049-.239 2.866-.102-.183-.211-4.385-.377-3.965-.165.42-.566 6.152-.629 5.967-.064-.184.29-4.605.248-7.074-.003-.21-.463-.176-.501-.382-.156-.844-.314-5.028-.434-4.683-.12.345-.205 5.098-.289 6.753-.054 1.058-.097 3.028-.215 3.172-.119.144-.34-2.291-.496-2.308-.156-.016-.367 2.958-.44 2.21-.141-1.456.307-7.366-.41-10.946-2.307-11.515-13.427-58.145-13.427-58.145s.471 21.964.367 
  23.469c-.104 1.505-.185 1.036-.346 1.436-.16.4.166 17.535.025 16.835-.14-.7-.657-23.798-.87-21.038-.213 2.761-2.331 18.153-2.512 21.929-.181 3.777 1.62 3.727 1.426.73-.066-1.025-.403-4.066-.482-3.043-.163 2.115-2.448-.33-2.605.056-.158.385 1.808 2.93 1.661 2.257-.147-.673-.676 1.624-.886.934-.21-.69-.236-2.117-.373-5.071-.065-1.403.205-4.173 0-4.213-.206-.039-.836 3.111-1.234 3.978-.234.51-1.113.664-1.154 1.223-.169 2.323-.086 5.931-.307 4.269-.221-1.661-.708-12.528-1.02-14.236-.312-1.709-.184 10.98-.402 12.429-.191 1.267-.795-2.458-.91-3.734-.151-1.695.162-6.214 0-6.434-.161-.219-.946 6.85-.968 5.115-.134-10.324-1.616-70.75-.864-82.759.094-1.5 2.971-4.525 5.046-5.147.361-.108.79-.152 1.259-.15h.061Zm4.209 11.929.399 20.127c11.118-9.476 12.483-18.776 10.652-19.498-2.147-.847-11.051-.629-11.051-.629Z"        
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path
        d="m408.121 375.805-4.213 38.367s5.914-.443 8.015-.331c1.565.083 4.593 1.001 4.593 1.001l-.182 3.693-.172.629.279.606.054 3.554-.644.418s.2.476.179.724c-.045.547-.453 2.562-.453 2.562l-1.482-.259s-3.417-.231-5.129-.269c-1.778-.04-5.54.029-5.54.029-.45 6.074-.579 12.577-.447 19.43 0 0 5.518-5.836 6.889-7.131.359-.339 1.337-.638 1.337-.638l6.465 5.443.065.506s2.285 2.059 
  2.523 2.531c.171.338-1.096.298-1.096.298.26.727-14.004 13.843-18.812 14.734-2.27.421-6.897-.619-6.897-.619-.436-6.648-1.631-12.074-3.152-19.085 0 0 5.952-58.445 6.301-70.456.044-1.5-4.715-1.632-4.203-1.613.511.019 7.368 2.055 7.271 1.728-.097-.327-7.875-3.481-7.854-3.691.022-.209 7.04 2.311 7.982 2.436.942.125-1.929-1.295-2.33-1.684-.157-.153.058-.513-.071-.653-.13-.14-.649-.036-.707-.185-.059-.15.154-.586.354-.711.201-.125 1.036.077.848-.039-.188-.115-2.065-.571-1.976-.655.088-.084 2.014.239 2.506.15.268-.048.241-.595.448-.682.208-.087.809.268.796.161-.013-.107-.553-.588-.873-.804-.321-.215-1.058-.407-1.052-.491.006-.084.9.056 1.088-.012.124-.045.158-.336.039-.395-.575-.283-3.58-1.256-3.487-1.302.093-.046 3.248.885 4.045 1.027.251.044.491-.14.742-.177.313-.047 1.092-.018 1.137-.103.044-.085-.563-.346-.869-.409-.305-.063-.713.081-.966.03-.211-.042-.539-.251-.553-.336-.014-.085.429-.09.471-.172.042-.082-.1-.27-.219-.319-.706-.289-4.121-1.392-4.017-1.416.103-.024 4.316 1.399 5.216 1.575.181.035.271-.392.185-.522-.087-.131-.759-.202-.704-.26.056-.057.728-.092 1.035-.084.273.007.765.229.807.134.043-.095-2.359-1.257-2.084-1.316.275-.06 3.609.938 3.735.956.127.018-4.092-1.851-3.95-1.841.142.01 7.06 2.787 9.426 3.81 1.624.701 3.611 1.911 4.771 2.324.689.245-2.522-1.221-3.172-1.555-1.559-.798-7.605-3.581-6.18-3.237 1.426.344 20.093 7.011 20.093 7.011l3.763 1.547-.04 3.023-.976 1.532-1.104 4.392-.652.337-1.214 3.188-.532-.085-.601-.779-14.853-5.57Z"
        style={{
          fill: "currentColor",
          stroke: "none",
          strokeWidth: ".42px",
        }}
        transform="translate(-117 -327)"
      />
      <path d="M170.293 52.454 153.287 49.5s-7.39-2.196-6.54-2.587c.646-.295 4.902.46 7.066.076.406-.072-3.895-1.741-2.698-2.67.924-.716 3.509-.563 3.46-.695-.487-1.313-6.54-2.06-7.864-3.368-.913-.901 3.646-.453 3.729-.948.082-.496-3.326-1.72-3.233-2.024.093-.304 3.297.338 3.789.197.429-.124-1.281-.992-.838-1.047.492-.06 3.79.684 3.79.684l16.522 3.747-.177 11.589ZM220.387 29.488l-10.045-2.438-.447 2.757-.232 1.699.704 1.488-1.831-.008-1.554 2.946-2.7 1.757 3.255.869 5.275 2.122 9.24 1.954-1.665-13.146ZM219.673 61.711l-1.763-16.577-3.586-1.117-.676-2.028-.985 1.59-1.075-2.109-2.7-1.205-2.246-1.393 2.495 28.36 10.536-5.52ZM194.708 99.69l9.2-8.37c-.095-2.933-6.564-14.198-7.67-10.797-.685 2.109 1.615 5.014.035 5.088-1.58.074-2.544 2.425-2.775 3.227-.231.802 1.21 10.852 1.21 10.852Z" 
        style={{
          fill: "currentColor"
        }}
      />
    </TitleImage>
    );
}

export default NavbarTitle;