import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
// TODO: remove ?
/*import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';*/
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import zipkinExporter from './zipkinExporter';

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'cerbere-frontend',
  }),
});
// TODO: to view trace in browser
/*provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));*/

provider.addSpanProcessor(new BatchSpanProcessor(zipkinExporter));
provider.register({
  contextManager: new ZoneContextManager(),
});

registerInstrumentations({
  instrumentations: [
    // TODO: remove ?
    /*getWebAutoInstrumentations({
        // load custom configuration for xml-http-request instrumentation
        '@opentelemetry/instrumentation-xml-http-request': {
          propagateTraceHeaderCorsUrls: [
              /.+/g,
            ],
        },
        // load custom configuration for fetch instrumentation
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: [
              /.+/g,
            ],
        },
      }),*/
  ],
});

const tracer = provider.getTracer('tracer-web');

export default tracer;
