import { MdArrowForwardIos } from 'react-icons/md';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { DARK_SCHEME_COLOR } from 'utility/css/values';

const Container = styled.div`
  width: 100%;
`;

const PathContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  color: #9ea4ad;
  font-size: 14px;
  font-weight: 70;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
`;

const SegmentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PathElement = styled.div`
  color: ${(props) => (props.isLast ? 'black' : 'inherit')};
  margin-right: 1vh;
  cursor: ${(props) => (props.isLast ? 'inherit' : 'pointer')};
  @media (prefers-color-scheme: dark) {
    color: ${(props) => (props.isLast ? DARK_SCHEME_COLOR : 'inherit')};
  }
`;

const PathArrow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 10px;
`;

const Path = (props) => {
  const navigate = useNavigate();

  return (
    <Container>
      <PathContainer>
        {props.segments.map((segment) => (
          <SegmentContainer key={segment.name}>
            <PathElement onClick={(e) => navigate(segment.path)}>
              {segment.name}
            </PathElement>
            <PathArrow>
              <MdArrowForwardIos fontSize="inherit" />
            </PathArrow>
          </SegmentContainer>
        ))}
        <PathElement isLast={true}>{props.lastSegment}</PathElement>
      </PathContainer>
    </Container>
  );
};

export default Path;
