const { ZipkinExporter } = require('@opentelemetry/exporter-zipkin');

// Add your zipkin url (`http://localhost:9411/api/v2/spans` is used as
// default) and application name to the Zipkin options.
// You can also define your custom headers which will be added automatically.
const options = {
  /*headers: {
    'my-header': 'header-value',
  },*/
  url: 'http://localhost:9411/api/v2/spans',
  // optional interceptor
  /*getExportRequestHeaders: () => {
    return {
      'my-header': 'header-value',
    }
  }*/
};
const zipkinExporter = new ZipkinExporter(options);

export default zipkinExporter;
