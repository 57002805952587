import tracer from 'utility/telemetry/tracer';
import { SpanStatusCode } from '@opentelemetry/api';

const traceError = (error, errorInfo) => {
    const span = tracer.startSpan('Error Boundary');
    /* TODO: attributes not working for addEvent ...
    span.addEvent('some log', {youpi: "coucou"});*/
    try {
      span.setAttribute('error.stack', JSON.stringify(errorInfo));
    } catch {
      span.setAttribute('error.stack', `${errorInfo}`);
    }
    span.setStatus({ code: SpanStatusCode.ERROR, message: `${error}` });
    span.end();
};

export default traceError;