import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ImYoutube2 } from 'react-icons/im';

const Container = styled.div`
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
`;

const Text = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  font-size: 20px;
  text-align: center;
`

const Icon = styled.div`
  transition: 0.2s ease;
  display: flex;
  justify-content: center;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 480px;
  width: 853px;
  @media only screen and (max-width: 853px) {
    width: 90%;
    padding-bottom: 50.64%;
  }
`

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const HomepagePresentation = ({ t, i18n }) => {
  return (
    <Container>
      <Text>
        <TitleContainer>
          {t('checkout_youtube')} :
        </TitleContainer>
        <Icon
            onClick={(e) =>
              window.open('https://www.youtube.com/channel/UCJrO9HKRgfuucGNQMZXkvRA/featured', '_blank')
            }
          >
            <ImYoutube2 size="100px" />
          </Icon>
      </Text>
      <Video>
        <Iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/8Z4hqwvk2yE"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Video>
    </Container>
  );
};

export default withTranslation()(HomepagePresentation);
