import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';
import { CategorySelected } from './Navbar';
import { withTranslation } from 'react-i18next';
import { MAX_MOBILE_VIEW } from 'utility/css/responsive';
import {
  DARK_SCHEME_COLOR_BACKGROUND,
  DARK_SCHEME_COLOR,
  NAVIGATION_BAR_HEIGHT,
} from 'utility/css/values';

import kaluluIcon from 'img/KaluluIconIOS.png';
import goodDayIcon from 'img/GoodDayIcon.png';
import bulletIcon from 'img/bulletIcon.png'

const Container = styled.div`
  width: 100%;
  height: 400px;
  background-color: white;
  color: black;
  @media (prefers-color-scheme: dark) {
    background-color: ${DARK_SCHEME_COLOR_BACKGROUND};
    color: ${DARK_SCHEME_COLOR};
  }
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    position: absolute;
    left: 0;
    top: ${NAVIGATION_BAR_HEIGHT}px;
    height: 100vh;
  }
`;

const Separator = styled.div`
  background-color: #d1dbe0;
  height: 1px;
  width: 100%;
  @media (prefers-color-scheme: dark) {
    background-color: #2F2F2F;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    overflow: auto;
    padding-top: 20px;
    font-size: 18px;
    flex-direction: column;
  }
`;

const Games = styled.div`
  display: flex;
  margin: 40px 0px 30px 40px;
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    margin: 10px 0px 0px 0px;
    width: 100vw;
  }
`

const Game = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: ${props => props.first ? '40px' : '0px'};
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    width: 100%;
  }
`
const ImageWrapper = styled.div`
  height: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    height: 150px;
  }
`

const GameText = styled.div`
  margin-top: 10px;
`

const GameTitle = styled.h2`
  text-align: center;
  font-size: 22px;
`

const Return = styled.div`
  display: flex;
  width: 100%;
  margin-left: 40px;
  justify-content: left;
  align-items: center;
  color: grey;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-weight: 100;
  margin-right: 5px;
`;

const ReturnText = styled.h2`
  font-size: 16px;
  font-weight: 100;
`;

const CategoryContent = ({
  t,
  i18n,
  dataType,
  isNavigationColumn,
  handleMouseLeave,
  setCategorySelected,
  navigateAndShow,
}) => {
  const lang = i18n.language;

  return (
    <Container
      id={dataType + '-navbar-content'}
      data-type={dataType}
      onMouseLeave={(e) =>
        !isNavigationColumn && handleMouseLeave(e)
      }
    >
      { !isNavigationColumn && <Separator data-type={dataType}/>}
      <Content data-type={dataType}>
        {isNavigationColumn && (
          <Return id={dataType + '-navbar-return'} onClick={() => setCategorySelected(CategorySelected.None)}>
            <ArrowWrapper>
              <MdArrowBack fontSize="inherit" />
            </ArrowWrapper>
            <ReturnText>{t('games').toUpperCase()}</ReturnText>
          </Return>
        )}
        <Games>
          <Game
              first={true}
              onClick={(e) =>
                navigateAndShow(
                  `/${lang}/${t('games').toLowerCase()}/bullet`
                )
              }
            >
              <ImageWrapper backgroundImage={bulletIcon} />
              <GameText>
                <GameTitle>Bullet: Surge</GameTitle>
              </GameText>
            </Game>
          <Game
              onClick={(e) =>
                navigateAndShow(
                  `/${lang}/${t('games').toLowerCase()}/jewel-run`
                )
              }
            >
              <ImageWrapper backgroundImage={goodDayIcon} />
              <GameText>
                <GameTitle>Jewel Run</GameTitle>
              </GameText>
            </Game>
            <Game
              onClick={(e) =>
                navigateAndShow(
                  `/${lang}/${t('games').toLowerCase()}/kalulu`
                )
              }
            >
              <ImageWrapper backgroundImage={kaluluIcon} />
              <GameText>
                <GameTitle>Kalulu</GameTitle>
              </GameText>
            </Game>
        </Games>
      </Content>
    </Container>
  );
};

export default withTranslation()(CategoryContent);
