import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export function translatePath(templatePath, lng) {
  if (templatePath === '') {
    return `/${lng}`;
  }
  const newPath = templatePath
    .split('/')
    .map((segment) => i18n.t(segment).toLowerCase())
    .join('/');
  return `/${lng}/${newPath}`;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: ['fr', 'en'],
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    fallbackLng: 'en',
    detection: {
      order: ['path', 'cookie', 'navigator', 'htmlTag'],
      caches: ['cookie', 'localStorage'],
    },
  });

export default i18n;
