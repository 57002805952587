import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import jewelRunImage from 'img/goodDay-miniature.png';
import bulletImage from 'img/bullet-miniature.png';
import steamLogo from 'img/Steam_icon_logo.svg';

const Container = styled.div`
    margin-bottom: 100px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    text-align: center;
    font-size: 40px;
    margin-right: 10px;
    @media only screen and (max-width: 853px) {
        font-size: 32px;
    }
    @media only screen and (max-width: 530px) {
        font-size: 25px;
    }
`;

const SteamLogo = styled.img`
  object-fit: contain;
  width: 50px;
`;

const MiniatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`;

const ImageWrapper = styled.div`
    margin: auto;
    max-width: 700px;
    @media (hover: hover) {
        &:hover {
            cursor: pointer;
        }
    }
`;

const Image = styled.img`
    width: 100%;
`

const Link = styled.h1`
    font-size: 30px;
    @media (hover: hover) {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        }
    }
    @media only screen and (max-width: 853px) {
        font-size: 25px;
    }
    @media only screen and (max-width: 580px) {
        font-size: 20px;
    }
`;

const handleClick = (link) => {
    window.open(link, '_blank');
  };

const KaluluPresentation = ({ t, i18n }) => {
    return (
        <Container>
            <TitleContainer>
                <Title>{t('games_on_steam')}</Title>
                <SteamLogo src={steamLogo}/>
            </TitleContainer>
            <Content>
                <MiniatureContainer>
                    <ImageWrapper
                        onClick={(e) =>
                            handleClick(
                              'https://store.steampowered.com/app/2097850/A_Good_Day/'
                            )
                          }
                    >
                        <Image src={jewelRunImage}></Image>
                    </ImageWrapper>
                    <Link
                        onClick={(e) =>
                            handleClick(
                              'https://store.steampowered.com/app/2097850/A_Good_Day/'
                            )
                          }
                    >Jewel Run</Link>
                </MiniatureContainer>
                <MiniatureContainer>
                    <ImageWrapper
                        onClick={(e) =>
                            handleClick(
                              'https://store.steampowered.com/app/2830200/Bullet/'
                            )
                          }
                    >
                        <Image src={bulletImage}></Image>
                    </ImageWrapper>
                    <Link
                        onClick={(e) =>
                            handleClick(
                              'https://store.steampowered.com/app/2830200/Bullet/'
                            )
                          }
                    >Bullet: Surge</Link>
                </MiniatureContainer>
            </Content>
        </Container>
    );
}

export default withTranslation()(KaluluPresentation);