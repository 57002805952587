import styled from 'styled-components';
import HomepagePresentation from 'components/homepage/HomepagePresentation';
import { withTranslation } from 'react-i18next';
import HomepageSwiper from 'components/homepage/slider/HomepageSwiper';
import KaluluPresentation from 'components/homepage/KaluluPresentation';

const Container = styled.div``;

const Homepage = ({ t, scrolling }) => {

  return (
    <Container>
      <HomepageSwiper scrolling={scrolling} />
      <HomepagePresentation />
      <KaluluPresentation />
    </Container>
  );
};

export default withTranslation()(Homepage);
