import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'utility/i18n/i18n';

import App from './App';

ReactDOM.render(
  <Suspense fallback="">
    <React.StrictMode>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
