import styled from 'styled-components';
import { useState } from 'react';
import CategoryContent from './CategoryContent';
import { animated, useSpring } from 'react-spring';
import { easeCircleOut } from 'd3-ease';
import { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { MdArrowForwardIos } from 'react-icons/md';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { withTranslation } from 'react-i18next';

import LocalizationModal from './LocalizationModal';
import { MAX_MOBILE_VIEW } from 'utility/css/responsive';
import {
  DARK_SCHEME_COLOR_BACKGROUND,
  DARK_SCHEME_COLOR,
  NAVIGATION_BAR_HEIGHT,
} from 'utility/css/values';
import { useMediaQuery } from 'utility/customHooks';
import { useNavigate } from 'react-router';
import NavbarTitle from './NavbarTitle';

const COLOR = '#414A53';

const Container = styled(animated.nav)`
  position: ${(props) => (props.$scrolling ? 'fixed' : 'absolute')};
  -webkit-position: ${(props) => (props.$scrolling ? 'fixed' : 'absolute')};
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
`;

const Navigation = styled.div`
  width: 100%;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.navbarIsSelected ? 'white' : 'inherit'};
  color: ${(props) => (props.navbarIsSelected ? COLOR : 'white')};
  @media (prefers-color-scheme: dark) {
    background-color: ${(props) =>
      props.navbarIsSelected ? DARK_SCHEME_COLOR_BACKGROUND : 'inherit'};
    color: ${(props) => (props.navbarIsSelected ? DARK_SCHEME_COLOR : 'white')};
  }
  transition: 0.3s ease;
  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: ${COLOR};
      @media (prefers-color-scheme: dark) {
        background-color: ${DARK_SCHEME_COLOR_BACKGROUND};
        color: ${DARK_SCHEME_COLOR};
      }
    }
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: left;
  height: 100%;
`;

const NavigateButton = styled.div`
  display: flex;
  font-size: 30px;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 10vw;
`;

/*TODO: like SectionContent => Container, need to have a y scrollbar y if too small*/
const Categories = styled.div`
  display: flex;
  margin-left: 10vw;
  font-size: 18px;
  font-weight: 300;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    margin-left: 0px;
    padding-top: 25px;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    position: absolute;
    height: 100vh;
    left: 0;
    top: ${NAVIGATION_BAR_HEIGHT}px;
    background-color: white;
    color: black;
    @media (prefers-color-scheme: dark) {
      background-color: ${DARK_SCHEME_COLOR_BACKGROUND};
      color: ${DARK_SCHEME_COLOR};
    }
  }
`;

const Category = styled.div`
  display: flex;
  height: 100%;
  margin-left: 40px;
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    margin-left: 0;
    padding-left: 40px;
    height: 40px;
    margin-bottom: 10px;
    width: 90%;
    align-items: none;
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-bottom: ${(props) => (props.isHovered ? '0px' : '3px')};
  border-bottom: ${(props) =>
    props.isHovered ? '3px solid ' + COLOR : 'none'};
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    align-items: none;
    justify-content: left;
    padding-bottom: 0;
    border-bottom: none;
  }
  @media (prefers-color-scheme: dark) {
    border-bottom: ${(props) =>
      props.isHovered ? '3px solid #C5C5C5' : 'none'};
  }
`;

const CategoryArrow = styled.div`
  display: flex;
  font-size: 22px;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const Middle = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const LanguageTitle = styled.div`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Text = styled.h3`
  font-weight: 200;
  font-size: 16px;
  @media only screen and (max-width: 1230px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 840px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 450px) {
    font-size: ${(props) => (props.isLang ? '12px' : '13px')};
  }
`;

export class CategorySelected {
  static None = 'none';
  static Games = 'games';
}

const Navbar = ({ t, i18n, animate, scrolling }) => {
  const isHoveredDevice = useMediaQuery('(hover: hover)');

  const lang = i18n.language;

  const [gamesIsHovered, setGamesIsHovered] = useState(false);
  const [categorySelected, setCategorySelected] = useState(
    CategorySelected.None
  );
  const [navigateIsOpen, setNavigateIsOpen] = useState(false);
  const [showLocalization, setShowLocalization] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNavigationColumn, setIsNavigationColumn] = useState(
    windowWidth <= MAX_MOBILE_VIEW
  );

  const handleMouseLeave = (event) => {
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget.dataset || !relatedTarget.dataset.type) {
      setGamesIsHovered(false);
      return;
    }
    if (relatedTarget.dataset.type === event.target.dataset.type) {
      setGamesIsHovered(true);
    }
  };

  const styleEaseOut = useSpring({
    to: {
      opacity: 1,
      top: 0,
    },
    from: {
      opacity: 0.5,
      top: -100,
    },
    config: {
      easing: (t) => easeCircleOut(t),
      duration: 700,
      tension: 220,
      friction: 120,
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    setIsNavigationColumn(windowWidth <= MAX_MOBILE_VIEW);
  }, [windowWidth]);

  const navigate = useNavigate();

  const navigateAndShow = (path) => {
    navigate(path);
    setNavigateIsOpen(false);
  };

  useEffect(() => {
    if (!navigateIsOpen) {
      setCategorySelected(CategorySelected.None);
    }
  }, [navigateIsOpen]);

  return (
    <Container
      style={animate ? styleEaseOut : null}
      $scrolling={scrolling}
    >
      {navigateIsOpen && windowWidth && isNavigationColumn && (
        <RemoveScrollBar />
      )}

      <Navigation
        navbarIsSelected={
          gamesIsHovered ||
          (navigateIsOpen && isNavigationColumn) ||
          scrolling
        }
      >
        <Left>
          {/* If window is "small", show and hide navigation with a button */}
          {isNavigationColumn && (
            <NavigateButton
              id='navbar-navigate-button'
              onClick={() => setNavigateIsOpen(!navigateIsOpen)}
            >
              {navigateIsOpen ? (
                <IoMdClose fontSize="inherit" />
              ) : (
                <AiOutlineMenu fontSize="inherit" />
              )}
            </NavigateButton>
          )}
          {/* If window is "big", display navigation categories inline */}
          {/* Or, if window is "small" (and no category is selected), display navigation categories in column
                        depending on wether the navigate button closes or opens the component (navigateIsOpen value)
                     */}
          {(!isNavigationColumn ||
            (navigateIsOpen &&
              categorySelected === CategorySelected.None)) && (
            <Categories id='navbar-categories'>
              <Category
                onClick={() =>
                  isNavigationColumn &&
                  setCategorySelected(CategorySelected.Games)
                }
              >
                <CategoryTitle
                  id='navbar-games'
                  isHovered={gamesIsHovered}
                  data-type="games"
                  onClick={() => 
                    !isHoveredDevice &&
                    !isNavigationColumn &&
                    setGamesIsHovered(!gamesIsHovered)
                  }
                  onMouseEnter={() =>
                    isHoveredDevice &&
                    !isNavigationColumn &&
                    setGamesIsHovered(true)
                  }
                  onMouseLeave={(e) =>
                    isHoveredDevice &&
                    !isNavigationColumn &&
                    handleMouseLeave(e)
                  }
                >
                  <Text>{t('games').toUpperCase()}</Text>
                </CategoryTitle>
                {isNavigationColumn && (
                  <CategoryArrow>
                    <MdArrowForwardIos fontSize="inherit" />
                  </CategoryArrow>
                )}
              </Category>
              <Category>
                <CategoryTitle
                  id='navbar-about'
                  onClick={(e) =>
                    navigateAndShow(`/${lang}/${t('path.about')}`)
                  }
                >
                  <Text>{t('about').toUpperCase()}</Text>
                </CategoryTitle>
              </Category>
            </Categories>
          )}
        </Left>

        <Middle>
          <NavbarTitle setNavigateIsOpen={setNavigateIsOpen}/>
        </Middle>

        <Right>
          <LanguageTitle
            id="navbar-lang"
            onClick={() => {
              setGamesIsHovered(false);
              setShowLocalization(!showLocalization);
            }}
          >
            <Text isLang={true}>{lang.toUpperCase()}</Text>
          </LanguageTitle>
        </Right>
      </Navigation>

      {/* If window is "big" and games category is hovered, display games category inline */}
      {/* Or, if window is "small" and games category is selected, display games category in column */}
      {((!isNavigationColumn && gamesIsHovered) || (isNavigationColumn && categorySelected === CategorySelected.Games)) &&
          <CategoryContent
            dataType="games"
            isNavigationColumn={isNavigationColumn}
            navigateAndShow={navigateAndShow}
            handleMouseLeave={handleMouseLeave}
            setCategorySelected={setCategorySelected}
          />
      }

      {showLocalization && (
        <LocalizationModal
          setShow={setShowLocalization}
          show={showLocalization}
        />
      )}
    </Container>
  );
};

export default withTranslation()(Navbar);
