import styled from 'styled-components';

import bulletMainImage from 'img/bullet-no-text.png';
import bulletAdelheid from 'img/bullet-adelheid.png';
import goodDayMultiImage from 'img/goodDay-multi.png';
import goodDayCrossImage from 'img/goodDay-crossplatform.png';
import bulletLogo from 'img/bullet-surge-logo.png';
import jewelRunLogo from 'img/Jewel-run-logo.png';
import { BUTTON_COLOR_BULLET, BUTTON_HOVER_COLOR_BULLET } from 'utility/css/values';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';

const Description = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0px 10% 0px 10%;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 50px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 40px;
  }
`;

const Text = styled.p`
  margin-bottom: 10px;
  font-size: 28px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

const Button = styled.button`
  margin-top: 30px;
  background-color: ${BUTTON_COLOR_BULLET};
  border-radius: 15px;
  padding: 10px 30px 10px 30px;
  border: none;
  color: white;
  font-size: 22px;
  font-weight: 300;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${BUTTON_HOVER_COLOR_BULLET};
    }
  }
`;

const BulletLogoImage = styled.img`
  width: 50%;
  @media only screen and (max-width: 853px) {
        width: 80%;
    }
`;

const GoodDayMain = ({ t, i18n }) => {

  const lang = i18n.language;
  const navigate = useNavigate();

  return (
    <Description>
        <Text>{t('good_day_main')}</Text>
        <BulletLogoImage src={bulletLogo} />
        <Button onClick={(e) =>
          navigate(`/${lang}/${t(
            'games'
          ).toLowerCase()}/bullet`)}>
          {t('learn_more').toUpperCase()}
        </Button>
      </Description>
  );
}

const GoodDayMultiPlayer = ({ t, i18n }) => {

  const lang = i18n.language;
  const navigate = useNavigate();

  return (
    <Description>
        <BulletLogoImage src={bulletLogo} />
        <Text>{t('bullet_puzzle')}</Text>
        <Button onClick={(e) =>
          navigate(`/${lang}/${t(
            'games'
          ).toLowerCase()}/bullet`)}>
          {t('learn_more').toUpperCase()}
        </Button>
      </Description>
  );
}

const GoodDayGraphics = ({ t, i18n }) => {

  const lang = i18n.language;
  const navigate = useNavigate();

  return (
    <Description>
        <BulletLogoImage src={jewelRunLogo} />
        <Text>{t('good_day_graphics')}</Text>
        <Button onClick={(e) =>
          navigate(`/${lang}/${t(
            'games'
          ).toLowerCase()}/jewel-run`)}>
          {t('learn_more').toUpperCase()}
        </Button>
      </Description>
  );
}

const GoodDayCrossPlatform = ({ t, i18n }) => {

  const lang = i18n.language;
  const navigate = useNavigate();

  return (
    <Description>
        <BulletLogoImage src={jewelRunLogo} />
        <Text>{t('good_day_crossplatform')}</Text>
        <Button onClick={(e) =>
          navigate(`/${lang}/${t(
            'games'
          ).toLowerCase()}/jewel-run`)}>
          {t('learn_more').toUpperCase()}
        </Button>
      </Description>
  );
}

const goodDayMain = {
  backgroundImage: bulletMainImage,
  backgroundPosition: 'left -200px center',
  backgroundPositionSmall: 'left -300px bottom',
  component: withTranslation()(GoodDayMain)
};

const goodDayMultiPlayer = {
  backgroundImage: bulletAdelheid,
  backgroundPosition: 'center center',
  backgroundPositionSmall: 'left -80px center',
  component: withTranslation()(GoodDayMultiPlayer)
};

const goodDayGraphics = {
  backgroundImage: goodDayMultiImage,
  backgroundPosition: 'center center',
  backgroundPositionSmall: 'center center',
  component: withTranslation()(GoodDayGraphics)
};

const goodDayCrossPlatform = {
  backgroundImage: goodDayCrossImage,
  backgroundPosition: 'bottom center',
  backgroundPositionSmall: 'bottom center',
  component: withTranslation()(GoodDayCrossPlatform)
};

export const gameSlideItems = [goodDayMain, goodDayMultiPlayer, goodDayGraphics, goodDayCrossPlatform];
