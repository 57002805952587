import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Path from 'components/Path';
import godotImage from 'img/Godot_logo.svg';
import logoImage from 'img/CerbereGamesLogo.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AboutContainer = styled.div`
  background-color: #2E343A;
  color: white;
  padding-top: 100px;
  width: 100%;
  min-height: 100vh;
`;

const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Logo = styled.img`
  height: 200px;
  object-fit: contain;
`;

const GodotLogo = styled.img`
  height: 80px;
  object-fit: contain;
`;

const Description = styled.div`
  text-align: center;
  margin: 100px 100px 100px 100px;

`;

const Line = styled.div`
 margin-bottom: 20px;
`;

const About = ({ t }) => {
  return (
    <Container id="about">
      <Path segments={[{ name: 'Home', path: '/' }]} lastSegment={t('about')} />
      <AboutContainer>
        <LogoContainer>
          <Logo src={logoImage} />
        </LogoContainer>
        <Description>
          <Line>{t('about_description_1')}</Line>
          <Line>{t('about_description_2')}</Line>
        </Description>
        <LogoContainer>
          <GodotLogo src={godotImage} />
        </LogoContainer>
      </AboutContainer>
    </Container>
  );
};

export default withTranslation()(About);
