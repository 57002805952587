import { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { changeLanguage } from 'i18next';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { languages } from 'utility/i18n/Localization';
import { MAX_MOBILE_VIEW } from 'utility/css/responsive';
import {
  DARK_SCHEME_COLOR,
  DARK_SCHEME_COLOR_BACKGROUND,
} from 'utility/css/values';

const Background = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (prefers-color-scheme: dark) {
    background: rgba(50, 50, 50, 0.7);
  }
`;

const Modal = styled(animated.div)`
  width: 50%;
  height: 50%;
  min-height: 300px;
  @media only screen and (max-width: ${MAX_MOBILE_VIEW + 'px'}) {
    width: 100%;
    height: 100%;
  }
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  color: black;
  border: 1px solid black;
  @media (prefers-color-scheme: dark) {
    color: ${DARK_SCHEME_COLOR};
    background: ${DARK_SCHEME_COLOR_BACKGROUND};
  }
`;

const Title = styled.h2`
  width: 90%;
  font-size: 18px;
  font-weight: 100;
  text-align: center;
`;

const ModalContent = styled.div`
  border-top: 1px solid black;
`;

const Language = styled.div`
  font-size: 14px;
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid black;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  margin-left: 15px;
  height: 60px;
`;

const CloseButton = styled(MdClose)`
  width: 10%;
  height: 30px;
`;

const LocalizationModal = ({ t, setShow, show }) => {
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 500,
    },
    to: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShow(false);
    }
  };

  const keyPress = (e) => {
    if ((e.key === 'Escape' || e.key === 'Enter') && show) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  });

  const changeLocalization = (code) => {
    changeLanguage(code);
    setShow(false);
  };

  return (
    <>
      <RemoveScrollBar />
      <Background ref={modalRef} onClick={closeModal}>
        <Modal id='localization-modal' style={animation}>
          <ModalWrapper>
            <Top>
              <Title>{t('select_language_title').toUpperCase()}</Title>
              <CloseButton id='localization-modal-button' onClick={() => setShow(false)} />
            </Top>
            <ModalContent>
              {languages.map(({ code, name }) => (
                <Language id={code} key={code} onClick={() => changeLocalization(code)}>
                  {name}
                </Language>
              ))}
            </ModalContent>
          </ModalWrapper>
        </Modal>
      </Background>
    </>
  );
};

export default withTranslation()(LocalizationModal);
